import { useEffect, useState } from "react";

  const useFetch = (url, refetch, payload) => {

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(false);

  useEffect(() => {

    setPending(true)      
      document.getElementById('load').style.display = 'flex';
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw Error("could not frtch the data");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          document.getElementById('load').style.display = 'none';
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setPending(false));

  }, [refetch]);

  return { data, pending, error };
};

export default useFetch;
