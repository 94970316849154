
import { LogLevel } from  '@azure/msal-browser'

export const Auth = {
    auth : {
        clientId : (window.location.href.indexOf('localhost') > -1) ? '0d288034-7568-4e43-a90a-518e16610792' : '7ae3a14f-bca2-4a94-9f95-95252d2147fb', 
        authority : 'https://login.microsoftonline.com/CerebraConsultingInc.onmicrosoft.com',
        redirectUrl : (window.location.href.indexOf('localhost') > -1) ? 'http://localhost:9000/' : '/',
        postLogoutRedirectUrl: (window.location.href.indexOf('localhost') > -1) ? 'http://localhost:9000/' : '/',
        navigateToLoginRequestUrl :false

    },
    cache : {
        cacheLocation : 'localStorage',
        storeAuthStateInCookie : false,
    },
    system : {
        loggerOptions : {
            loggerCallback : (level, message, containsPii) => {
                if(containsPii) {
                    return;
                }
                switch(level) {
                    case LogLevel.Error:
                        // console.log(message);
                        return;
                    case LogLevel.Info:
                        // console.log(message);
                        return;
                    case LogLevel.Verbose:
                        // console.log(message);
                        return;
                    case LogLevel.Warning:
                        // console.log(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
}

export const loginRequest = {
    scope : ["user.read","profile","openid"],
    prompt: "select_account"
}
