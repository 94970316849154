export const menuItems = {
    sx : { 
        display: "block",
        "& .MuiListItemButton-root" : {
            background : "transparent !important",
            transition: 'background 1s',
            borderRadius : "4px",
            margin  : "5px 0px",
        },
        "& .MuiListItemButton-root: hover" : {
            background : "rgba(56, 148, 102) !important",
            borderRadius : "4px",
            
        },
        "& .Mui-selected" : {
            background : "rgba(56, 148, 102) !important",
            borderRadius : "4px",
        }, 
    }
}

export const paperStyle = {
    sx  :  {
        background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
        backdropFilter  :   "blur(15px) brightness(150%) ",
        padding         :   "24px",
        height          :   "540px",
        border          :   "1px solid #00d2ff70",
        borderRadius    :   "4px"
    }
}
export const LabelPaperStyle = {
    sx  :  {
        background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
        backdropFilter  :   "blur(15px) brightness(150%) ",
        padding         :   "24px",
        minHeight       :   "260px",
        border          :   "1px solid #00d2ff70",
        borderRadius    :   "4px"
    }
}
export const summaryPaperStyle = {
    sx  :  {
        backgroundColor :   "rgba(240, 240, 240, 0.085)",
        backdropFilter  :   "blur(10px) brightness(50%) ",
        padding         :   "24px",
        minHeight       :   "260px",
        border          :   "1px solid rgba(240, 240, 240, 0.2)",
        borderRadius    :   "4px",
    }
}

export const ModalPaperStyle = {
    sx  :  {
        background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
        backdropFilter  :   "blur(15px) brightness(150%) ",
        padding         :   "24px",
        minHeight       :   "460px",
        border          :   "1px solid #00d2ff70",
        borderRadius    :   "4px"
    }
}

export const ModalPaperTableStyle = {
    sx  :  {
        backgroundColor :   "transparent",
        backdropFilter  :   "blur(15px) brightness(50%) ",
        height          :   "460px",
        // border          :   "1px solid rgba(240, 240, 240, 0.2)",
        // borderRadius    :   "4px",
    }
}

export const GridProps = [
    {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        item: true,
         sx : {
            margin : "8px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 8,
        lg: 8,
        xl: 8,
        item: true,
        sx : {
            margin : "8px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        item: true,
        sx : {
            margin : "8px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        item: true,
    },
    {
        xs: 12,
        sm: 12,
        md: 3,
        lg: 3,
        xl: 3,
        item: true,
        sx : {
            margin : "12px 0px"
        }
    },
    // for button alignment props 
    
    {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
        xl: 3,
        item: true,
        sx : {
            margin : "12px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        item: true,
        sx : {
            margin : "12px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        item: true,
        sx : {
            margin : "12px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 8,
        lg: 8,
        xl: 8,
        item: true,
        sx : {
            margin : "12px 0px"
        }
    },
    {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 5,
        xl: 5,
        item: true,
    },
    {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 7,
        xl: 7,
        item: true,
    },
    {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        item: true,
    },
];
export const TabProps = {
    sx : {
        "&.MuiTabs-root .MuiTabs-indicator" : {
            backgroundImage : "linear-gradient(to bottom right, #18ff8c, #18ff8c) !important"
        },
        "& .Mui-selected" : {
            color : "#18ff8c !important"
        },
        "&.MuiTabPanel-root" : {
            padding : "24px 10px"
        }
    }
}
export const TabPanelProps = {
    sx : {
        "&.MuiTabPanel-root" : {
            padding : "24px 10px"
        }
    }
}

export const DividerProps ={
    sx: {
        "&.MuiDivider-root" : {
            color: "white",
            marginBottom : "2vh"
        },
        "&.MuiDivider-root::after" : {
            color: "white",
            borderTop: "thin solid #ffff"
        },
        "&.MuiDivider-root::before" : {
            color: "white",
            borderTop: "thin solid #ffff"
        }
    }
}


export const TableProps = [
    {
        sx : {
            "& .MuiTableCell-head" : {
                backgroundColor : "#1F2229",
                borderBottom: "2px solid rgba(101, 103, 119, 0.21)",
                padding : "16px",
            },
        }
    },
    {
        sx : {
            "& .MuiTableCell-body" : {
                backgroundColor : "rgba(31, 31, 35, 0.6)",
                borderBottom: "2px solid rgba(101, 103, 119, 0.21)",
                padding : "16px",
            },
        }
    },

]

export const formProps = {
    sx  : {
        "& .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgba(101, 103, 119, 0.9)",
           
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth : "1px",
            borderColor: "rgba(160, 160, 173, 1)"
        }, 
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderWidth : "2px",
            borderColor : "rgba(160, 160, 173, 1)",
        },
        "& .MuiOutlinedInput-input" : {
            color : "#fff",
            backgroundColor: "rgba(22, 22, 34, 0.375)",
        },
    }
}

export const selectProps = {
    sx  : {
        "& .MuiSelect-root, .MuiSelect-icon" : {
            color : "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgba(101, 103, 119, 0.9)",
            borderRadius    :   "4px",
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth : "1px",
            borderColor: "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        }, 
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderWidth : "2px",
            borderColor : "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        },
        "& .MuiOutlinedInput-input" : {
            color : "#fff",
            backgroundColor: "rgba(22, 22, 34, 0.375)",
        },
    }
}

export const botInputProps = {
    sx  : {
        "& .MuiSelect-root, .MuiSelect-icon" : {
            color : "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth : "1px",
            borderColor: "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        }, 
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderWidth : "2px",
            borderColor : "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        },
        "& .MuiOutlinedInput-input" : {
            color : "#fff",
            // backgroundColor: "rgba(22, 22, 34, 0.375)",
        },
    }
}

export const selectTextAreaProps = {
    sx  : {
        "& .MuiSelect-root, .MuiSelect-icon" : {
            color : "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgba(101, 103, 119, 0.9)",
            borderRadius    :   "4px",
            backgroundColor : "rgba(22, 22, 34, 0.375)",
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth : "1px",
            borderColor: "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        }, 
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderWidth : "2px",
            borderColor : "rgba(160, 160, 173, 1)",
            borderRadius    :   "4px",
        },
        "& .MuiOutlinedInput-input" : {
            color : "#fff",

        },
    }
}

export const selectDropbox = {
    sx : {
        backgroundColor : "rgba(22, 22, 34)",
        color : "#fff",
        minHeight: "auto !important",
        maxHeight : "400px !important",
        '& .MuiMenuItem-root': {
            fontSize : "15px"
        },
        borderRadius    :   "4px",
        overflowY:"auto",
        
    } 
}


export const dateProps = {
    sx  : {
        "& .MuiSvg-root" : {
            color : "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgba(101, 103, 119, 0.9)",
           
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth : "1px",
            borderColor: "rgba(160, 160, 173, 1)"
        }, 
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
            borderWidth : "2px",
            borderColor : "rgba(160, 160, 173, 1)",
        },
        "& .MuiOutlinedInput-input" : {
            color : "#fff",
            backgroundColor: "rgba(22, 22, 34, 0.075)",
        },
    }
}

export const buttonProps = [
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #389466, #42b883) !important',
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
            minWidth:"110px",
        }
    },
    {
        sx : {
            background: "#ffffff20 !important",
            border: "1px solid #ffffff30",
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
            width:"110px",
        }
    },
    {
        sx : {
            // background: "#C69749 !important",
            border: "1px solid #C69749 !important",
            color:"#C69749",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
            width:"150px",
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #76767660, #81818170) !important',
            border: "1px solid #76767660",
            color:"black",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
            width:"110px",
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #389466, #42b883) !important',
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #4477CE, #4477DE) !important',
            border: '1px solid "#18ff8c" !important',
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            width:"110px",
            margin:"0px 10px",
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #76767660, #81818170) !important',
            border: "1px solid #76767660",
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
            width:"110px",
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #389466, #42b883) !important',
            color:"white",
            textTransform : "capitalize",
            fontSize:"18px",
            margin:"0px 10px",
            minWidth:"110px",
            maxWidth : "auto",
            fontWeight : "bold"
        }
    },
    {
        sx : {
            background: "#ffffff20 !important",
            border: "1px solid #ffffff30",
            color:"#389466",
            textTransform : "capitalize",
            fontSize:"18px",
            margin:"0px 10px",
            minWidth:"110px",
            maxWidth : "auto",
            fontWeight : "bold"
        }
    },
    {
        sx : {
            backgroundImage: 'linear-gradient(to bottom right, #389466, #42b883) !important',
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
        }
    },
    {
        sx : {
            background: "#ffffff20 !important",
            border: "1px solid #ffffff30",
            color:"white",
            textTransform : "capitalize",
            fontSize:"16px",
            margin:"0px 10px",
        }
    },

]

export const paperMui = [
    {
        sx : {
            backgroundColor :   "rgba(56, 148, 102, 0.42)",
            padding         :   "10px 12px",
            border          :   "1px solid #18ff8c",
            borderRadius    :   "4px",
            textTransform   :   "capitalize",
            backdropFilter  :   "blur(15px) brightness(150%) ",
        }
    },
    {
        sx : {
            background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
            border          :   "1px solid #00d2ff70",
            backdropFilter  :   "blur(15px) brightness(150%) ",
            padding         :   "24px",
            borderRadius    :   "4px",
            maxheight       :   "100%",
            overFlowY       :   "scroll",
        }
    },
    {
        sx : {
            background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
            border          :   "1px solid #00d2ff70",
            backdropFilter  :   "blur(15px) brightness(150%) ",
            padding         :   "24px",
            borderRadius    :   "4px",
            minHeight       :   "70vh",
            maxheight       :   "100%",
            overFlowY       :   "scroll",
        }
    },
    {
        sx : {
            background      :   'linear-gradient(90deg, #3F2B9620 0%, #A8C0FF30 100%)',
            border          :   "1px solid #00d2ff70",
            backdropFilter  :   "blur(15px) brightness(150%) ",
            padding         :   "24px",
            borderRadius    :   "4px",
        }
    },
]

export const GraphBox = [
    {
        sx  : {
            display   : "block",
            overflowX : "auto",
            overflowY : "auto",
            height    : "420px",
        }
    },
    {
        sx  : {
            display   : "block",
            overflowX : "auto",
            overflowY : "hidden",
            height    : "420px",
        }
    },

]

export const DatgridStyles = {
    sx  : {
        "&.MuiDataGrid-root" : {
            backgroundColor : "#1F2229",
            border : "1.5px solid #1F2229",
        },
        "& .MuiButton-root, .MuiSvgIcon-root, .MuiInput-input" : {
            color : "#ffffff",
        },
        "& .MuiDataGrid-columnHeaderTitle, .MuiTablePagination-root" : {
            color : "#18ff8c",
            fontSize : "16px"
        },
        "& .MuiDataGrid-cellContent" : {
            color : "#c7c7c7",
        },
        "& .MuiDataGrid-withBorderColor" : {
            borderColor : "rgba(101, 103, 119, 0.21)"
        },
        "& .MuiPaginationItem-root": {
            color : "#18ff8c",
        },
        "& .MuiInput-root:after" : {
            borderBottom : "2px solid #fff"
        }
    }
}

export const WHDatgridStyles = {

    sx  : {
        "&.MuiDataGrid-root" : {
            backgroundColor : "rgba(0, 0, 0, 0.45)",
            border : "2px solid rgba(222, 222, 222, 0.2)",
        },
        "& .MuiButton-root, .MuiInput-input" : {
            color : "#c7c7c7",
        },
        "& .MuiDataGrid-columnHeaderTitle, .MuiTablePagination-root" : {
            color : "#18ff8c",
            fontSize : "16px"
        },
        "& .MuiDataGrid-cellContent" : {
            color : "#ffff",
        },
        "& .MuiDataGrid-withBorderColor" : {
            borderColor : "rgba(101, 103, 119)"
        },
        "& .MuiPaginationItem-root": {
            color : "#18ff8c",
        },
        "& .MuiInput-root:after" : {
            borderBottom : "2px solid #c7c7c7"
        },
        
        "& .MuiSvgIcon-root," :{
            color : "#ffffff",
        }
    }

}



export const WHGridProps = {
    sx : {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
        xl: 3,
        item: true,
    },
}

export const WHDisableStyles = {
    "& .css-39lilv-MuiButtonBase-root-MuiCheckbox-root.Mui-checked," :{
        color:'#ffff'

    }
}

export const WHModalStyles = {
    sx : {
        transform: 'translate(2%, -10%)',
        width: "80%",
    
        // backgroundColor: "rgba(240, 240, 240, 0.085) ",
        // border          :   "1.5px solid rgba(240, 240, 240, 0.4)",
        borderRadius    :   "8px",
        margin : '10% 10%'  
        
    },
}

export const WHModalpaperStyle = {
    sx  :  {
        backgroundColor :   "rgba(19 , 82 , 151 , 0) ",
        backdropFilter  :   "blur(15px) brightness(100%) ",
        padding         :   "24px",
        minHeight       :   "540px",
        border          :   "1.5px solid rgba(240, 240, 240, 0.4)",
        borderRadius    :   "8px",
    }
}

export const WHDialogStyles = {
    sx : {     
        
        '& .MuiDialogContent-root': {
            backgroundColor : "rgba(0, 0, 0, 0.45)",
            border : "2px solid rgba(222, 222, 222, 0.2)",
          },
          
          '& .MuiDialogActions-root': {
            // padding: theme.spacing(1),
          },
        
    },
}

export const WHDialogTitleStyles = {
    sx : {
        // transform: 'translate(2%, -10%)',
        backgroundColor: "rgba(240, 240, 240, 0.085) ",
        border          :   "1.5px solid rgba(240, 240, 240, 0.4)",
        borderRadius    :   "8px",
        
    },
}


export const DialogPaperStyle = {
    sx : {
        backgroundColor :   "rgba(240, 240, 240, 0.085) ",
        backdropFilter  :   "blur(50px) brightness(150%) ",
        padding         :   "24px",
        minHeight       :   "340px",
        border          :   "1.5px solid rgba(240, 240, 240, 0.4)",
        borderRadius    :   "8px",
    },
}


export const DrawerStyle = {
    sx  :  {
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        border          :   "1.5px solid #18ff8c",
        borderRadius    :   "8px",
        backdropFilter  :   "blur(50px) brightness(150%) ",
    }
}

export const WHHeaderStyle = {
    sx  :  {
        backgroundColor :   "rgba(66, 184, 131, 0.2) ",
        backdropFilter  :   "blur(50px) brightness(150%) ",
        padding         :   "12px",
        border          :   "1.5px solid #18ff8c",
        borderRadius    :   "8px",
        textTransform   :   "capitalize"
    }
}

export const dialogStyle = {
    sx : {
        backdropFilter: "blur(15px)", 
        backgroundColor: "rgba(222, 222, 222, 0.025)",
        "&.MuiPaper-root, .MuiDialog-paper" : {
            backgroundColor :   "rgba(222, 222, 222, 0.025) ",
            backdropFilter  :   "blur(50px) brightness(150%) ",
            border          :   "1.5px solid rgba(240, 240, 240, 0.4)",
            borderRadius    :   "8px",
            color           :   "#fff",
        }
    }
}

export const switchProps = {
    sx : {
        '&.MuiButtonBase-root, .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track ' : {
            backgroundColor: 'green !important',
        },
        '&.MuiButtonBase-root, .MuiSwitch-switchBase.Mui-checked' : {
            color: 'green !important',
        }
    }
}