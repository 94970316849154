import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Profilepic from "../assets/profile_pic.jpg";
import { Tooltip, Avatar, Collapse, Grid, } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ListIcon from "@mui/icons-material/List";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PestControlIcon from "@mui/icons-material/PestControl";

import WarehouseIcon from "@mui/icons-material/Warehouse";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";

import DetailsIcon from "@mui/icons-material/Details";
import AddCardIcon from "@mui/icons-material/AddCard";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DangerousIcon from "@mui/icons-material/Dangerous";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import PowerIcon from "@mui/icons-material/Power";
import MopedIcon from "@mui/icons-material/Moped";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import FactoryIcon from "@mui/icons-material/Factory";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SendIcon from "@mui/icons-material/Send";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import BallotIcon from "@mui/icons-material/Ballot";
import EvStationIcon from "@mui/icons-material/EvStation";
import ViewListIcon from "@mui/icons-material/ViewList";
import GradingIcon from "@mui/icons-material/Grading";
import CableIcon from "@mui/icons-material/Cable";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import SmsIcon from "@mui/icons-material/Sms";

import { menuItems } from "../assets/styles";
import DvrIcon from "@mui/icons-material/Dvr";
import { Link, useNavigate } from "react-router-dom";

const Menubar = (props) => {

  const navigate = useNavigate()
  
  let userdata = JSON.parse(localStorage.getItem("userMenu"));
  let userMenu = JSON.parse(localStorage.getItem("userMenu"));

  let mainmenu = userMenu[0]["MainIds"];
  let submenu = userMenu[0]["SubIds"];
  let Roles = ["CEO", "DEO", "FRA", "REO", "MFA"];

  const drawerWidth = 280;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundColor: "rgba(22, 22, 34, 0.75)",
    borderColor: "rgba(39, 55, 77)",
    borderWidth: "0 thin 0 0",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgba(22, 22, 34, 0.75)",
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    borderColor: "rgba(39, 55, 77)",
    borderWidth: "0 thin 0 0",
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // const settings = ["Profile", "Account", "Dashboard", "Logout"];
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const handleOpenUserMenu = (event) => {
  //     setAnchorElUser(event.currentTarget);
  // };
  // const handleCloseUserMenu = () => {
  //     setAnchorElUser(null);
  // };

  const [selectedIndex, setSelectedIndex] = React.useState(props.item);
  const [selectedSubIndex, setSelectedSubIndex] = React.useState(props.subitem);
  const [selectedSubIndexSelect, setSelectedSubIndexSelect] = React.useState(
    props.item
  );
  const handleListItemClick = (event, index, subindex) => {
    props.activation(index, subindex);
    setSelectedIndex(index);
    setSelectedSubIndex(subindex);

    let data = JSON.parse(localStorage.getItem("userMenu"));
    let newdata = [
      {
        ID: data[0]["ID"],
        RoleName: data[0]["RoleName"],
        RoleCode: data[0]["RoleCode"],
        MainIds: data[0]["MainIds"],
        SubIds: data[0]["SubIds"],
        Permissions: data[0]["Permissions"],
        Menu: index,
        SubMenu: subindex,
        Name: data[0]["Name"],
      },
    ];
    localStorage.setItem("userMenu", JSON.stringify(newdata));
  };
  
  console.log(selectedIndex);
  console.log(selectedSubIndex);
  console.log(selectedSubIndexSelect);
  const openSubMenu = (index, subIndex) => {
    setSelectedIndex(index);
    setSelectedSubIndexSelect(subIndex);
  };

  const closeSubMenu = (index, subIndex) => {
    setSelectedIndex(index);
    setSelectedSubIndexSelect(subIndex);
  };

  const [darkThemeValue, setDarkThemeValue] = useState(false);

  useEffect(() => {
    if (darkThemeValue === false) {
      let element = document.querySelector(".dark-theme");
      element.style.backgroundColor = "#1f476d";
    } else {
      let element = document.querySelector(".dark-theme");
      element.style.backgroundColor = "#1D1D1D";
    }
  }, [darkThemeValue]);

  const Logout = () => {
    window.location.pathname = "/logout";
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "rgba(39, 55, 77, 0.2)",
          boxShadow: "none",
          backdropFilter: "blur(15px) brightness(100%)",
          border: open && "1px solid rgba(56, 148, 102)",
          borderWidth: "0 0 thin 0",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // sx={{
            //   marginRight: 5,
            //   // ...(open && { display: "none" }),
            // }}
          >
            <MenuIcon sx={{
              marginRight: 5, ...(open && { display: "none" }),
            }} onClick={handleDrawerOpen} className="font-22 icon-white" />
            <Typography variant="h6" noWrap component={Box} onClick={()=> {navigate('/home'); setSelectedIndex(0)}}>
              <IconButton className="icon-green font-22">
                <ViewInArIcon />
              </IconButton>
                <span className={"headerText font-green"} style={{textDecorationLine: "none"}}>
                  Inventory Management
                </span>
            </Typography>
          </IconButton>
          
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              className="font-22 icon-white"
              onClick={() => {
                setDarkThemeValue((e) => !e);
              }}
            >
              {darkThemeValue === false ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
            <IconButton className="font-22 icon-white">
              <NotificationsIcon />
            </IconButton>
            <Box sx={{ padding: "0 10px" }}>
              <Tooltip
                title={userdata[0]["Name"] + " - " + userdata[0]["RoleCode"]}
              >
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt={userdata[0]["Name"]} src={Profilepic} />
                </IconButton>
              </Tooltip>
            </Box>
            <IconButton
              className="font-22 icon-white"
              onClick={() => {
                Logout();
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader>
          {open === true && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowRightIcon className="font-22 font-white" />
              ) : (
                <KeyboardArrowLeftIcon className="font-22 font-white" />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List
          onClick={() => {
            setOpen(true);
          }}
        >
          <ListItem disablePadding {...menuItems}>
            <ListItemButton
              component={Link}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              to="/home"
              selected={window.location.pathname === "/home" ? true : false}
              onClick={(event) => {
                handleListItemClick(event, 0, 0);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon className="font-22 font-white" />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
                className="font-22 font-white"
              />
            </ListItemButton>
          </ListItem>

          {/* USER MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("1") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 1
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(1, 1)
                    : closeSubMenu(1, 0)
                  : openSubMenu(1, 1);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 1 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 1, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleAltIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="User Management"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 1 ? (
                    submenu["1"] && submenu["1"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["1"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["1"] && submenu["1"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 1 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["1"].includes("1") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/usermanagement/createuser"
                        selected={selectedSubIndex === 1 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 1, 1);
                        }}
                      >
                        <ListItemIcon>
                          <PersonAddAlt1Icon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Create User"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["1"].includes("2") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/usermanagement/userlist"
                        selected={selectedSubIndex === 2 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 1, 2);
                        }}
                      >
                        <ListItemIcon>
                          <ListIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Users List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["1"].includes("4") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/usermanagement/roles"
                        selected={selectedSubIndex === 4 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 1, 4);
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentIndIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Roles & Responsibility"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["1"].includes("5") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/usermanagement/changepassword"
                        selected={selectedSubIndex === 5 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 1, 5);
                        }}
                      >
                        <ListItemIcon>
                          <VpnKeyIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Change Password"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* USER MANAGEMENT MENU LINKS ENDS */}

          {/* ASSET MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("2") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 2
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(2, 2)
                    : closeSubMenu(2, 0)
                  : openSubMenu(2, 2);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 2 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 2, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PowerIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="Asset Management"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 2 ? (
                    submenu["2"] && submenu["2"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["2"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["2"] && submenu["2"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 2 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["2"].includes("7") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/createflc"
                        selected={selectedSubIndex === 7 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 7);
                        }}
                      >
                        <ListItemIcon>
                          <BallotIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Create FLC"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("8") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/flclist"
                        selected={selectedSubIndex === 8 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 8);
                        }}
                      >
                        <ListItemIcon>
                          <GradingIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="FLC List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["2"].includes("9") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/training"
                        selected={selectedSubIndex === 9 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 9);
                        }}
                      >
                        <ListItemIcon>
                          <EvStationIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="create Training"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("10") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/traininglist"
                        selected={selectedSubIndex === 10 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 10);
                        }}
                      >
                        <ListItemIcon>
                          <ListAltIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Training List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("11") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/unittracking"
                        selected={selectedSubIndex === 11 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 11);
                        }}
                      >
                        <ListItemIcon>
                          <ShareLocationIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Asset Tracking"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("13") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/unitstatus"
                        selected={selectedSubIndex === 13 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 13);
                        }}
                      >
                        <ListItemIcon>
                          <ViewListIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Asset Status"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("14") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/unitreplacement"
                        selected={selectedSubIndex === 14 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 14);
                        }}
                      >
                        <ListItemIcon>
                          <CableIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Asset Replacement"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}

                  {submenu["2"].includes("8") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/unit/replacementlist"
                        selected={selectedSubIndex === 14 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 2, 15);
                        }}
                      >
                        <ListItemIcon>
                          <DvrIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Replacement List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* ASSET MANAGEMENT MENU LINKS ENDS */}

          {/* ORDER MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("3") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 3
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(3, 3)
                    : closeSubMenu(3, 0)
                  : openSubMenu(3, 3);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 3 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 3, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LocalShippingIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="Order Management"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 3 ? (
                    submenu["3"] && submenu["3"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["3"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["3"] && submenu["3"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 3 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["3"].includes("27") === true &&
                  userdata[0]["RoleCode"] === "WIC" ? (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/order/create_request"
                        selected={selectedSubIndex === 27 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 3, 27);
                        }}
                      >
                        <ListItemIcon>
                          <NoteAddIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Raise An Order"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  ) : (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/order/create"
                        selected={selectedSubIndex === 27 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 3, 27);
                        }}
                      >
                        <ListItemIcon>
                          <NoteAddIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Create Order"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["3"].includes("28") === true &&
                  userdata[0]["RoleCode"] === "WIC" ? (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/order/requestlist"
                        selected={selectedSubIndex === 28 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 3, 28);
                        }}
                      >
                        <ListItemIcon>
                          <FactCheckIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Raised Order List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  ) : (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/order/list"
                        selected={selectedSubIndex === 28 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 3, 28);
                        }}
                      >
                        <ListItemIcon>
                          <FactCheckIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Order List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["3"].includes("30") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to={
                          Roles.includes(userdata[0]["RoleCode"]) === true
                            ? "/order/allocation"
                            : "/order/managerallocation"
                        }
                        selected={selectedSubIndex === 30 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 3, 30);
                        }}
                      >
                        <ListItemIcon>
                          <SendIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Allocation"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* ORDER MANAGEMENT MENU LINKS ENDS */}

          {/* WAREHOUSE MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("4") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 4
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(4, 4)
                    : closeSubMenu(4, 0)
                  : openSubMenu(4, 4);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 4 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 4, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <WarehouseIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="EV Warehouse"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 4 ? (
                    submenu["4"] && submenu["4"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["4"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["4"] && submenu["4"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 4 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["4"].includes("36") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/evwarehouse/createwarehouse"
                        selected={selectedSubIndex === 36 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 4, 36);
                        }}
                      >
                        <ListItemIcon>
                          <AddBusinessIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Create Warehouse"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["4"].includes("37") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/evwarehouse/warehouselist"
                        selected={selectedSubIndex === 37 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 4, 37);
                        }}
                      >
                        <ListItemIcon>
                          <FactoryIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="EV Warehouses"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* WAREHOUSE MANAGEMENT MENU LINKS ENDS */}

          {/* ISSUE MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("5") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 5
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(5, 5)
                    : closeSubMenu(5, 0)
                  : openSubMenu(5, 5);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 5 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 5, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <QuestionAnswerIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="Issue Management"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 5 ? (
                    submenu["5"] && submenu["5"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["5"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["5"] && submenu["5"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 5 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["5"].includes("41") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/issuemanagement/issuerequest"
                        selected={selectedSubIndex === 41 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 5, 41);
                        }}
                      >
                        <ListItemIcon>
                          <CallToActionIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Issue Request"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["5"].includes("43") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/issuemanagement/issuelist"
                        selected={selectedSubIndex === 43 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 5, 43);
                        }}
                      >
                        <ListItemIcon>
                          <SmsIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Issue List"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* ISSUE MANAGEMENT MENU LINKS ENDS */}

          {/* CONSUMABLES MANAGEMENT MENU LINKS STARTS */}
          {mainmenu.includes("7") && (
            <ListItem
              disablePadding
              {...menuItems}
              onClick={() => {
                selectedIndex === 7
                  ? selectedSubIndexSelect === 0
                    ? openSubMenu(7, 7)
                    : closeSubMenu(7, 0)
                  : openSubMenu(7, 7);
              }}
            >
              <ListItemButton
                component={Link}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={selectedIndex === 7 ? true : false}
                onClick={(event) => {
                  handleListItemClick(event, 7, selectedSubIndex);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ChromeReaderModeIcon className="font-22 font-white" />
                </ListItemIcon>
                <ListItemText
                  primary="EV Consumables"
                  sx={{ opacity: open ? 1 : 0 }}
                  className="font-22 font-white"
                />
                {open ? (
                  selectedSubIndexSelect === 7 ? (
                    submenu["7"] && submenu["7"].length > 0 ? (
                      <KeyboardArrowDownIcon className="font-22 font-white" />
                    ) : (
                      <></>
                    )
                  ) : submenu["7"].length > 0 ? (
                    <KeyboardArrowRightIcon className="font-22 font-white" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </ListItemButton>
              {submenu["7"] && submenu["7"].length > 0 && (
                <Collapse
                  in={selectedSubIndexSelect === 7 ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {submenu["7"].includes("57") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/consumables/stockdetails"
                        selected={selectedSubIndex === 57 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 7, 57);
                        }}
                      >
                        <ListItemIcon>
                          <DetailsIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Stock Information"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                  {submenu["7"].includes("59") === true && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        sx={{ pl: 4 }}
                        to="/consumables/stockentry"
                        selected={selectedSubIndex === 59 ? true : false}
                        onClick={(event) => {
                          handleListItemClick(event, 7, 59);
                        }}
                      >
                        <ListItemIcon>
                          <AddCardIcon className="font-22 font-white" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Entry Stock Details"
                          className="font-22 font-white"
                        />
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              )}
            </ListItem>
          )}
          {/* CONSUMABLES MANAGEMENT MENU LINKS ENDS */}

          {/* CHAT BOT MENU LINKS STARTS */}
          <ListItem disablePadding {...menuItems}>
            <ListItemButton
              component={Link}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              to="/chatbot"
              selected={selectedIndex === 8 ? true : false}
              onClick={(event) => {
                handleListItemClick(event, 8, 0);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SupportAgentIcon className="font-22 font-white" />
              </ListItemIcon>
              <ListItemText
                primary="IM Bot"
                sx={{ opacity: open ? 1 : 0 }}
                className="font-22 font-white"
              />
            </ListItemButton>
          </ListItem>
          {/* ISSUE MANAGEMENT MENU LINKS ENDS */}
        </List>
      </Drawer>
    </>
  );
};

export default Menubar;
