import React, { useEffect } from "react";
import { GridProps, botInputProps, buttonProps, paperStyle } from "../assets/styles";
import { Box, Button, Card, Divider, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Stack, Tooltip, Typography } from "@mui/material";
import Header from "../UI/header";
import { useState } from "react";
import VoiceAssist from "./voiceAssist";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Thinking from "./thinking";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const ChatBot = ({chat, setChat}) => {

    const [iamMessage , setIamMessage] = useState("")
    const [iamVoice , setIamVoice] = useState("")
    const [listening , setListening] = useState("")

    // const [chat, setChat] = useState([
    //   {
    //     message: "Hi, how can i help you ?", 
    //     bot: true, 
    //     sql: null
    //   }
    // ])

    const [messageLoading, setMessageLoading] = useState(false)

    function sendMessage(){
      setMessageLoading(true)
      fetch(`${process.env.REACT_APP_API}/ImBot/IM_Bot`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "textmessage": iamVoice.length> 0 ? iamVoice : iamMessage,
        }),
      }).then(res => res.json())
        .then(data => {setChat((prev)=>[...prev, {message: data['message'], bot: true , sql: data['sql']}]); setMessageLoading(false)})
    }

    function clear(){
      fetch(`${process.env.REACT_APP_API}/ImBot/IM_Bot_reset`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }).then(res => res.json())
        .then(data => {
          console.log(data)
          setChat([
            {
              message: "Hi, how can i help you?", 
              bot: true, 
              sql: null
            }
          ])
        })
    }

   

  //   const htmlFrom = (htmlString) => {
  //     const cleanHtmlString = DOMPurify.sanitize(htmlString,
  //       { USE_PROFILES: { html: true } });
  //     const html = parse(cleanHtmlString);
  //     return html;
  // }


    const enterKey = event =>{
      if (event.key === 'Enter') {
        if(iamVoice.length> 0 ? iamVoice : iamMessage !== ''){
          setChat((prev)=>[...prev, {message: iamVoice.length> 0 ? iamVoice : iamMessage, bot: false, sql: null}])
          setIamMessage('')
          sendMessage()
        }
      }
    }

    const submitButton = () => {
      if(iamVoice.length> 0 ? iamVoice : iamMessage !== ''){
        setChat((prev)=>[...prev, {message: iamVoice.length> 0 ? iamVoice : iamMessage, bot: false, sql: null}])
        setIamMessage('')
        sendMessage()
      } 
    }

      const scrollToBottom = () => {
        const chatContainer = document.getElementById('chat-container');
        chatContainer.scrollTop = chatContainer.scrollHeight;
      };

      useEffect(() => {
          scrollToBottom();
          AOS.init();
      },[chat])

    const ChartCard = function({data, index}){

      const [like ,setLike] = useState(false)
      const [dislike ,setDislike] = useState(false)
  
      function feedback(value){

        fetch(`${process.env.REACT_APP_API}/ImBot/IM_Bot_feedback`, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "textmessage": value,
          }),
        }).then(res => res.json())
          .then(data => {
            console.log(data)
            alert("Thanks for your feedback")
            value === "positive" ? setLike(true) : setDislike(true)
            value === "negative" ? setLike(false) : setDislike(false)   
          })        
      }

        const [showPopup, setShowPopup] = useState(false)
        const history = data.message
        const sqlQuery = data.sql
        const myMessages = data.bot

            const splitParagraphs = history.replace(/\n/g,'\n');
            const splitLine = splitParagraphs.split('\n')
            const messageText =  splitLine.map((paragraph) => (
              <p className='font-16 font-700'>{paragraph}</p>
            ))

        return(
          <Box display={"flex"}>
          <Box display={"flex"} flex={1} flexDirection={ myMessages ? 'row' : 'row-reverse'}>
          <Card sx={{ marginBottom: 1, 
                      flexDirection : myMessages ? 'row' : 'row-reverse',
                      maxWidth: '90%',
                      minWidth: "10%",
                      bgcolor: myMessages ? "#59d69e" : "#EEEEEE", 
                      padding: "15px 25px",
                      borderRadius: myMessages ? '25px 25px 25px 0px' :'25px 25px 0px 25px',
                      }}>
                        <Stack direction="row" justifyContent={"space-between"}>
                          <Typography variant="h6" className='font-12 font-montserrat font-400' >{myMessages ? "Bot" : 'Me'} :</Typography>
                          {sqlQuery !== null && <VisibilityIcon onClick={()=> {setShowPopup(e => !e)}}/>}
                        </Stack>

                        {showPopup && 
                        <>
                          <Typography className="font-montserrat font-14 font-500" > Query : <span className='font-montserrat font-14 font-700'>{sqlQuery}</span></Typography><br/>
                          <Divider style={{backgroundColor: "black"}}/>
                        </>
                        } 
                        <Typography justifyContent={"start"}>{messageText}</Typography>
                         {index !== 0 && myMessages && <Box display={"flex"} justifyContent={"flex-end"} position={"relative"} marginTop={2}>
                            <ThumbUpIcon style={{color: like && "green"}} onClick={() => feedback("positive")} fontSize="small" sx={{marginRight: '15px'}} />
                            <ThumbDownIcon style={{color: dislike && "red"}} onClick={() => feedback("negative")} fontSize="small"/>
                          </Box>
                        }                 
              </Card>
            </Box>
          </Box>
      )
    }

  return (
    <>
      <Header>IM Bot</Header>
      <Grid container direction={"row"} columnSpacing={2}>
        <Grid sm={12} item data-aos="fade-up">
          <Paper {...paperStyle}>
          <Box
               data-aos="fade-up"
               data-aos-duration="1000"
              height={"56vh"}
              id = "chat-container"
              marginBottom={2}
              sx={{
                backgroundColor: "rgba(22, 22, 34, 0.375)",
                // border: "1px solid rgba(101, 103, 119, 1)",
                borderRadius: "4px",
                padding:" 12px",
                overflowY: "auto"
              }}
            > 
            {chat.length > 0 && chat.map((data, index) => <ChartCard data={data} index={index}/>)}
            {messageLoading && <Thinking/>}
            </Box>

            <Box>
              <Grid container direction={"row"} columnSpacing={3}>
                <Grid {...GridProps[6]} rowSpacing={2} sx={{ height: "80px" }}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="input"
                      {...botInputProps}
                      variant="outlined"
                      value={iamVoice.length> 0 ? iamVoice : iamMessage}
                      onChange={(e)=> setIamMessage(e.target.value)}
                      onKeyDown={enterKey}
                      placeholder={listening ? "Listening . . ." :"Replay"}
                      pattern="[A-Za-z]"
                      type="text"
                      startAdornment={
                          <IconButton edge="start">
                            <Tooltip title="Clear Conversation" placement="top">
                              <RefreshIcon onClick={clear} className={"icon-green"} />
                            </Tooltip>
                          </IconButton>
                      }
                      endAdornment = {
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <VoiceAssist submitButton={submitButton} setListening={setListening} setIamVoice={setIamVoice}/>
                            </IconButton>
                            <IconButton edge="end">           
                                <Button onClick={submitButton} variant="contained" {...buttonProps[0]}>send</Button>
                            </IconButton>
                        </InputAdornment>
                    }
                    ></OutlinedInput>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
                    
    </>
  );
};

export default ChatBot;
